import SEO from "../Components/SEO"

function Order() {
  return (
    <section id='order' className='order align-items-center justify-content-between'>
      <SEO
        title="Order Japanese Food Online | Mimik Sushi | Acton"
        description="Online order page of Mimik Sushi"
        name="Mimik Sushi"
        type="Online order Page"
      />
      <div className='container' data-aos="fade-up">
        <div className='section-header'>
          <p><span>ORDER</span></p>
        </div>

        <div className='text-center'>
          <a href='https://deliveroo.co.uk/menu/London/south-acton/mimik-sushi' rel="noopener noreferrer" target="_blank"><button className='btn-order me-3 mt-3'>Deliveroo</button></a>
          <a href='https://www.just-eat.co.uk/restaurants-mimik-sushi-acton/menu' rel="noopener noreferrer" target="_"><button className='btn-order me-3 mt-3'>Just Eat</button></a>
          <a href='https://www.ubereats.com/gb/store/mimik-sushi/6GhDZZ2BRWCnWi5aAJIePQ?diningMode=DELIVERY' rel="noopener noreferrer" target="_"><button className='btn-order me-3 mt-3'>Uber Eats</button></a>
        </div>
      </div>
    </section>
  )
}

export default Order