import SEO from "../Components/SEO";
import MenuContent from "../Components/MenuContent";

function Menu() {
  const foodSection = [
    {
      label: 'starters',
      description: '',
      menuItems: [
        { name: 'Miso Soup', description: '(GF)', price: '£3.50', ingredients: '' },
        { name: 'Edamame Normal', description: '(VG)', price: '£3.99', ingredients: '' },
        { name: 'Edamame Spicy', description: '(VG)', price: '£3.99', ingredients: '' },
        { name: 'Chicken Karaage', description: '(5 pc)', price: '£5.00', ingredients: '' },
        { name: 'Duck Spring Roll', description: '(3 pc)', price: '£4.50', ingredients: '' },
        { name: 'Veg Spring Roll', description: '(3 pc, VG)', price: '£3.99', ingredients: '' },
        { name: 'Prawn Gyoza', description: '(5 pc)', price: '£5.99', ingredients: '' },
        { name: 'Chicken Gyoza', description: '(5 pc)', price: '£5.50', ingredients: '' },
        { name: 'Duck Gyoza', description: '(5 pc)', price: '£5.99', ingredients: '' },
        { name: 'Veg Gyoza', description: '(5 pc, VG)', price: '£4.99', ingredients: '' },
        { name: 'Prawn Tempura', description: '(5 pc)', price: '£8.50', ingredients: '' },
      ]
    },
    {
      label: 'nigiri',
      description: '2 pieces of rice pillow with toppings',
      menuItems: [
        { name: 'Salmon', description: '', price: '£3.50', ingredients: '' },
        { name: 'Tuna', description: '', price: '£3.99', ingredients: '' },
        { name: 'Seabass', description: '', price: '£3.50', ingredients: '' },
        { name: 'Eel', description: '', price: '£4.99', ingredients: '' },
        { name: 'Prawn', description: '', price: '£3.99', ingredients: '' },
        { name: 'Mango', description: '(vg)', price: '£3.50', ingredients: '' },
        { name: 'Avocado', description: '(vg)', price: '£3.50', ingredients: '' },
      ]
    },
    {
      label: 'sashimi',
      description: '5 slices of raw fish',
      menuItems: [
        { name: 'Salmon', description: '', price: '£5.50', ingredients: '' },
        { name: 'Tuna', description: '', price: '£5.99', ingredients: '' },
        { name: 'Seabass', description: '', price: '£5.50', ingredients: '' },
      ]
    },
    {
      label: 'gunkan',
      description: '2 pieces of nori-wrapped sushi rice topped with a choice of ingredient',
      menuItems: [
        { name: 'Salmon', description: '', price: '£3.00', ingredients: '' },
        { name: 'Tuna', description: '', price: '£3.50', ingredients: '' },
        { name: 'Masago', description: '', price: '£3.00', ingredients: '' },
        { name: 'Wakame', description: '(vg)', price: '£2.50', ingredients: '' },
      ]
    },
    {
      label: 'tartare',
      description: 'ground raw fish with sauce and dressing',
      menuItems: [
        { name: 'Salmon', description: '', price: '£7.50', ingredients: '' },
        { name: 'Tuna', description: '', price: '£7.99', ingredients: '' },
      ]
    },
    {
      label: 'tataki',
      description: '5 slices of seared fish',
      menuItems: [
        { name: 'Salmon', description: '', price: '£6.99', ingredients: '' },
        { name: 'Tuna', description: '', price: '£7.50', ingredients: '' },
      ]
    },
    {
      label: 'temaki',
      description: 'a traditional Japanese handroll w/ rice & fillings',
      menuItems: [
        { name: 'Salmon', description: '', price: '£5.50', ingredients: 'salmon / japanese mayo / sesame seeds / avocado' },
        { name: 'Tuna', description: '', price: '£5.99', ingredients: 'tuna / japanese mayo / green onion' },
        { name: 'Salmon Philadelphia', description: '', price: '£5.50', ingredients: 'salmon / cream cheese / avocado / spring onion / tobiko' },
        { name: 'Vegatarian', description: '(vg)', price: '£4.50', ingredients: 'avocado / cucumber / salad / mango' },
      ]
    },
    {
      label: 'chirashi',
      description: 'sushi rice topped with raw fish',
      menuItems: [
        { name: 'Salmon', description: '', price: '£8.50', ingredients: '' },
        { name: 'Tuna', description: '', price: '£9.50', ingredients: '' },
        { name: 'Mix', description: '', price: '£9.50', ingredients: '' },

      ]
    },
    {
      label: 'salad & poke bowls',
      description: '',
      menuItems: [
        { name: 'Salmon', description: '', price: '£8.99', ingredients: '' },
        { name: 'Tuna', description: '', price: '£9.50', ingredients: '' },
        { name: 'Chicken Katsu', description: '', price: '£8.50', ingredients: '' },
        { name: 'Japanese Kanikama Salad', description: '', price: '£8.50', ingredients: '' },
        { name: 'Kaiso Salad', description: '(vg)', price: '£5.50', ingredients: '' },

      ]
    },
    {
      label: 'hosomaki',
      description: '6 pieces thin rolls of rice w/ seaweed on the outside',
      menuItems: [
        { name: 'Salmon', description: '', price: '£4.99', ingredients: '' },
        { name: 'Tuna', description: '', price: '£5.50', ingredients: '' },
        { name: 'Crab', description: '', price: '£4.50', ingredients: '' },
        { name: 'Avocado', description: '(vg)', price: '£4.50', ingredients: '' },
        { name: 'Mango', description: '(vg)', price: '£4.50', ingredients: '' },
        { name: 'Cucumber', description: '(vg)', price: '£4.50', ingredients: '' },

      ]
    },
    {
      label: 'futomaki',
      description: '6 pieces of large sushi rolls',
      menuItems: [
        { name: 'Special Futomaki', description: '', price: '£10.50', ingredients: 'salmon / tuna / cucumber / avocado / japanese mayo / orange tobiko / prawn tempura' },
        { name: 'Chicken Katsu', description: '', price: '£9.50', ingredients: 'grilled chicken / avocado / cucumber / spicy mayo' },
        { name: 'Mimik', description: '', price: '£9.50', ingredients: 'soft crab fry / avocado / green onion / spicy mayo' },
      ]
    },
    {
      label: 'uramaki',
      description: '8 pieces inside out sushi roll',
      menuItems: [
        { name: 'Salmon Avocado Roll', description: '', price: '£8.99', ingredients: 'salmon / japanese mayo / sesame seeds / avocado' },
        { name: 'Salmon Crunchy Roll', description: '', price: '£9.50', ingredients: 'salmon skin/ cucumber / japanese mayo/ teriyaki sauce / sesame seeds' },
        { name: 'Spicy Tuna Cucumber', description: '', price: '£9.99', ingredients: 'tuna / sriracha / cucumber / masago / spring onion' },
        { name: 'California Roll', description: '', price: '£8.50', ingredients: 'crab meat / japanese mayo / cucumber / orange masago / avocado /sesame seeds' },
        { name: 'Ebi Katsu Roll', description: '', price: '£8.99', ingredients: 'ebi katsu/ orange tobiko / avocado / japanese mayo / fried onions / teriyaki sauce' },
        { name: 'Veg Roll', description: '(vg)', price: '£8.50', ingredients: 'avocado / carrot / cucumber / white sesame / japanese mayo' },
        { name: 'Philadelphia Roll', description: '', price: '£8.50', ingredients: 'salmon / cream cheese / avocado / tobiko' },
      ]
    },
    {
      label: 'mimik hot rolls',
      description: '6 pieces cooked sushi rolls',
      menuItems: [
        { name: 'Mimik Tiger', description: '', price: '£10.50', ingredients: 'prawn tempura/ cream cheese / salmon / spring onion / japanese mayo / teriyaki sauce' },
        { name: 'Salmon', description: '', price: '£8.50', ingredients: 'fried salmon / cream cheese / avocado / spicy mayo / teriyaki sauce' },
        { name: 'Salmon Lava Tempura', description: '', price: '£8.50', ingredients: 'salmon maki tempura / salmon tartare / masago / sriracha / japanese mayo / spring onion' },
        { name: 'Tuna Lava Tempura', description: '', price: '£8.50', ingredients: 'tuna maki tempura / tuna tartare / masago / sriracha / japanese mayo / spring onion' },
      ]
    },
    {
      label: 'mimik special rolls',
      description: '10 pieces special sushi rolls',
      menuItems: [
        { name: 'Rainbow', description: '', price: '£15.50', ingredients: 'salmon / tuna / avocado / japanese mayo / orange tobiko/ teriyaki sauce / fried onion / prawn tempura /spring onion' },
        { name: 'Black Tiger', description: '', price: '£12.50', ingredients: 'ebi katsu / avocado / japanese mayo / black sushi rice' },
        { name: 'Green Dragon', description: '', price: '£12.50', ingredients: 'prawn tempura / japanese mayo / orange tobiko / teriyaki sauce' },
        { name: 'Salmon Top', description: '', price: '£12.50', ingredients: 'salmon / japanese mayo / avocado / orange tobiko' },
        { name: 'Mimik Special', description: '', price: '£12.50', ingredients: 'salmon / cream cheese / mango / green onion / fried onion / black tobiko' },
        { name: 'Red Dragon', description: '', price: '£14.90', ingredients: 'prawn tempura, avocado, sliced tuna on top garnished with spicy truffle mayo, teriyaki sauce, fried sweet potato' },
        { name: 'Yamato Dragon', description: '', price: '£14.80', ingredients: 'prawn katsu, avocado, torched salmon nigiri w/ spicy mayo, soy glaze, sesame seeds, sweet potato garnish' },
        { name: 'Spicy Salmon Dragon', description: '', price: '£14.60', ingredients: 'salmon cucumber, topped with sliced salmon, sesame seeds, sriracha sauce, fried onions' },
        { name: 'Spicy Tuna Dragon', description: '', price: '£14.90', ingredients: 'tuna cucumber topped with sliced tuna garnished, sesame sauce, sriracha sauce, fried onions' },
        { name: 'Spicy Salmon Lava', description: '', price: '£13.60', ingredients: 'salmon marinated w/ sriracha, spring onion, cucumber, topping tartare salmon, garnish w/ tempura super crunc' },
      ]
    },
    {
      label: 'hot dishes',
      description: '',
      menuItems: [
        { name: 'Chicken Katsu Curry', description: '', price: '£9.00', ingredients: 'deep fry chicken, japanese katsu sauce, served with rice salad' },
        { name: 'Prawn Katsu Curry', description: '', price: '£11.00', ingredients: 'deep fry prawn, japanese katsu sauce, served with rice salad' },
        { name: 'Veg Katsu Curry', description: '', price: '£9.00', ingredients: 'deep fry vegetable katsu, japanese katsu sauce, served with rice salad' },
        { name: 'Chicken Karaage', description: '', price: '£11.00', ingredients: 'plain steamed rice, 5 pieces of marinated chicken in garlic and ginger, deep fried served with lime and spicy truffle mayo' },
        { name: 'Salmon Teriyaki', description: '', price: '£13.00', ingredients: 'grilled salmon, steamed broccoli, plain steamed rice, teriyaki sauce' },
        { name: 'Beef Teriyaki', description: '', price: '£15.00', ingredients: 'grilled black angus beef (150 g), steamed broccoli, plain steamed rice, teriyaki sauce' },
        { name: 'Chicken Teriyaki', description: '', price: '£11.00', ingredients: 'grilled chicken, steamed broccoli, plain steamed rice, teriyaki sauce' },
      ]
    },
    {
      label: 'yaki noodles',
      description: 'stir fried thin, egg yakisoba noodles or thick, white udon noodles',
      menuItems: [
        { name: 'Seafood', description: '', price: '£12.00', ingredients: 'king prawn, crabstick, scallops, sesame oil, carrot, cabbage, onions, garnished with micro herbs, yakisoba sauce' },
        { name: 'Chicken', description: '', price: '£11.00', ingredients: 'grilled chicken, sesame oil, carrot, cabbage, onions, garnished with micro herbs, yakisoba sauce' },
        { name: 'Yasai Veg', description: '(vg)', price: '£9.30', ingredients: 'mixed vegetable, fried tofu, sesame oil, carrot, cabbage, onions, garnished with micro herbs, yakisoba sauce' },
        { name: 'Beef', description: '', price: '£13.00', ingredients: 'grilled beef, sesame oil, carrot, cabbage, onions, garnished with micro herbs, yakisoba sauce' },
        { name: 'Salmon', description: '', price: '£13.00', ingredients: 'grilled salmon, sesame oil, carrot, cabbage, onions, garnished with micro herbs, yakisoba sauce' },
      ]
    },
    {
      label: 'sushi platters',
      description: 'platters to share',
      menuItems: [
        { name: 'Salmon Lover', description: '', price: '£14.99', ingredients: '15 total pc, tempura maki (6pc), salmon nigiri (3pc), salmon sashimi (6pc)' },
        { name: 'Tuna Lover', description: '', price: '£15.99', ingredients: '15 total pc, tempura maki & tuna temaki avocado (6pc), tuna nigiri (3pc), tuna sashimi (6pc)' },
        { name: 'Yamato Maki Set', description: '', price: '£23.99', ingredients: '30 total pc, special dragon rolls, salmon tempura maki, california uramaki, salmon nigiri' },
        { name: 'Tokyo Freestyle', description: '', price: '£17.99', ingredients: '16 total pc, gunkan special (2pc), nigiri (3pc), special dragin rolls (6pc), assorted sashimi (5pc)' },
        { name: 'Yamato Freestyle', description: '', price: '£39.99', ingredients: '36 total pc, gunkan special (4pc), nigiri (4pc), tempura maki (8pc), special dragon rolls (8pc), mixed sashimi (12pc)' },
        { name: 'Osaka Freestyle', description: '', price: '£75.99', ingredients: '70 total pc, uramaki, hosomaki, gunkan special, nigiri, tempura maki, hot sushi tiger roll, special rolls, mixed sashimi' },
        { name: 'Veg Freestyle', description: '(vg)', price: '£15.99', ingredients: '16 total pc, green veg dragon rolls, avocado tempura maki, veg uramaki, avocado nigiri' },
      ]
    },
    {
      label: 'extras',
      description: '',
      menuItems: [
        { name: 'Plain Rice', description: '', price: '£2.99', ingredients: '' },
        { name: 'Sushi Rice', description: '', price: '£2.99', ingredients: '' },
        { name: 'Curry Sauce', description: '', price: '£2.50', ingredients: '' },
      ]
    },
    {
      label: 'desserts',
      description: '',
      menuItems: [
        { name: 'Mochi', description: '', price: '£3.99', ingredients: 'mango / strawberry / vanilla' },
        { name: 'Dorayaki', description: '', price: '£2.99', ingredients: 'chocolate / vanilla' },
      ]
    },
  ]

  return (
    <section id="menu" className="menu">
      <SEO 
        title="Mimik Sushi Menus — Japanese Food and Drinks"
        description="Menu page of Mimik Sushi"
        name="Mimik Sushi"
        type="Menu Page"
      />
      <div className="container" data-aos="fade-up">
        <div className="section-header">
          <p><span>OUR MENU</span></p>
        </div>

        {/* <div className="select-container">
        <select defaultValue="default">
          <option disabled value="default">SELECT MENU</option>
          <option value="#starters">STARTERS</option>
          <option value="#nigiri">NIGIRI</option>
          <option value="#sashimi">SASHIMI</option>
          <option value="#gunkan">GUNKAN</option>
          <option value="#tartare">TARTARE</option>
          <option value="#tataki">TATAKI</option>
          <option value="#temaki">TEMAKI</option>
          <option value="#chirashi">CHIRASHI</option>
          <option value="#poke-bowls">SALAD & POKE</option>
          <option value="#hosomaki">HOSOMAKI</option>
          <option value="#futomaki">FUTOMAKI</option>
          <option value="#uramaki">URAMAKI</option>
          <option value="#hot-rolls">MIMIK HOT ROLLS</option>
          <option value="#special-rolls">MIMIK SPECIAL ROLLS</option>
          <option value="#hot-dishes">HOT DISHES</option>
          <option value="#yaki-noodles">YAKI NOODLES</option>
          <option value="#sushi-platters">SUSHI PLATTERS</option>
          <option value="#extras">EXTRAS</option>
          <option value="#desserts">DESSERTS</option>
        </select>
      </div> */}

        <div className="tab-content" data-aos="fade-up" data-aos-delay="300">
          <MenuContent value={foodSection} />
          <div className="text-center pt-5">
            <a href='assets/files/Mimik ALY Menu.pdf' rel="noopener noreferrer" target="_blank"><button className='btn-menu me-3 mt-3'>Download All You Can Eat Menu</button></a>
            <a href='assets/files/Mimik Menu.pdf' rel="noopener noreferrer" target="_blank"><button className='btn-menu me-3 mt-3'>Download Food Menu</button></a>
          </div>
        </div>
      </div>
    </section>
  )
}



export default Menu;