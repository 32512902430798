import { Clock, Facebook, GeoAlt, Instagram, Telephone, Tiktok } from "react-bootstrap-icons"

function Footer() {
    return (
        <>
            <footer id="footer" className="footer">
                <div className="container">
                    <div className="row gy-3">
                        <div className="col-lg-3 col-md-6 d-flex">
                            <i className="icon"><GeoAlt/></i>
                            <div>
                                <h4> Address</h4>
                                <p>
                                269 High St, London W3 9BT<br />
                                </p>
                            </div>

                        </div>
                        <div className="col-lg-3 col-md-6 footer-links d-flex">
                            <i className=" icon"><Telephone/></i>
                            <div>
                                <h4> Reservations</h4>
                                <p>
                                    <strong>Phone:</strong> 020 7167 8370<br />
                                    <strong>Email:</strong> mimiksushi@gmail.com<br />
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 footer-links d-flex">
                            <i className="icon"><Clock/></i>
                            <div>
                                <h4> Opening Hours</h4>
                                <p>
                                    <strong>Everyday:</strong> 12PM-10PM<br />
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-6 footer-links">
                            <h4>Follow Us</h4>
                            <div className="social-links d-flex">
                                <a href="https://www.tiktok.com/@mimiksushi" className="tiktok" target="_"><Tiktok/></a>
                                <a href="https://www.facebook.com/profile.php?id=100083613935638" className="facebook" target="_"><Facebook /></a>
                                <a href="https://www.instagram.com/mimiksushi_acton/" className="instagram" target="_"><Instagram/></a>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="container">
                    <div className="copyright">
                        &copy; Copyright <strong><span>Mimik Sushi</span></strong> 2023. All Rights Reserved
                    </div>
                    <div className="credits">
                        {/* All the links in the footer should remain intact. */}
                        {/* You can delete the links only if you purchased the pro version. */}
                        {/* Licensing information: https://bootstrapmade.com/license/ */}
                        {/* Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/yummy-bootstrap-restaurant-website-template/ */}
                        {/* Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a> */}
                    </div>
                </div>
            </footer>
            {/* <a href="#" className="scroll-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a> */}
            <div id="preloader"></div>
        </>
    )
}

export default Footer