//imports...
import SEO from "../Components/SEO";


function Home() {
  return (
    <section id="home" className="home">
      <SEO 
        title="Mimik Sushi - Japanese Cuisine With a Modern Take"
        description="Home page of Mimik Sushi"
        name="Mimik Sushi"
        type="Home Page"
      />
      <div className="container" data-aos="fade-up">
        <div className="section-header">
          <p><span>ABOUT US</span></p>
        </div>
        <div className="row gy-4">
          <div className="col-lg-12 d-flex align-items-end " data-aos="fade-up" data-aos-delay="300">
            <div className="content ps-0 ps-lg-7 ">
              <h3 className="text-align-center">A modern take</h3>
              <p className="home-text">
                We are a concept sushi restaurant with a
                personal touch. We believe that good food is
                for everyone and everyone should try it. Our
                menu caters for everyone who likes good
                food and it`s open for an experience once
                you come to our location. Sushi is our passion.
              </p>
              <h3>Taste the difference</h3>
              <p className="home-text">
                We offer special sushi with amazing flavors, ingredients and combinations to all of those who like good food and want to have a great time. We always look to create a unique experience with every meal so that our customers feel special every time they come. Our experienced cooks, skilled sushi chefs and friendly servers bring out the love and passion of our food, all that’s left is for you to experience it.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Home;