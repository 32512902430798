import React, { useState } from 'react'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Swal from 'sweetalert2'

import { newsletterApi } from '../api/newsletter'

function Newsletter() {
    const [errMsg, setErrMsg] = useState("");
    const [subscribe, isSubscribe] = useState(false);

    const newsLetterForm = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            email: '',
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            first_name: Yup.string().max(20, 'Must be 40 character or less').required('First name is required'),
            last_name: Yup.string().max(20, 'Must be 40 character or less').required('Last name is required'),
            email: Yup.string().max(40, 'Must be 40 character or less').required('Email is required'),
        }),
        onSubmit: (values) => {
            newsletterApi.subscribe(values).then((data) => {
                if (data.success) {
                    Swal.fire({
                        text: data.msg,
                        icon: 'success',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#ff6766',
                    })
                    isSubscribe(true)
                } else {
                    setErrMsg(data.msg);
                    return;
                }
            });
        }
    });

    return (
        <section id="newsletter" className="newsletter">
            <div className="container" data-aos="fade-up">
                {
                    !subscribe ?
                        <>
                            <div className="section-header">
                                <p><span>SUBSCRIBE</span></p>
                            </div>

                            <div className="section-text">
                                <p>Sign up with your email address to receive news and updates</p>
                            </div>
                        </>
                        : ''
                }

                <div className="section-header">
                    {
                        subscribe ?
                            <div className='text-center text-light'>
                                <p className="fs-1 ">Thank you for subscribing!</p>
                                <p className="fs-5">Welcome on board! We'll be in touch with you with our latest news and events.</p>
                            </div>
                            : ''
                    }
                </div>


                {
                    !subscribe ?
                        <form className="php-email-form p-3 p-md-4">
                            <div className="row">
                                <div className='col-xl-12'>
                                    <div className="row gy-4">
                                        {errMsg && !subscribe ? <div className='book-a-table error-message'> {errMsg}</div> : ''}
                                    </div>
                                </div>
                                <div className="col-xl-4 form-group">
                                    <input
                                        type="text"
                                        name="first_name"
                                        className="form-control"
                                        id="first_name"
                                        placeholder="First Name*"
                                        required
                                        onChange={newsLetterForm.handleChange}
                                        onBlur={newsLetterForm.handleBlur}
                                        value={newsLetterForm.values.first_name}
                                    />
                                    {newsLetterForm.touched.first_name && newsLetterForm.errors.first_name ? <p className='field-err-msg'>{newsLetterForm.errors.first_name}</p> : null}

                                </div>
                                <div className="col-xl-4 form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="last_name"
                                        id="last_name"
                                        placeholder="Last Name*"
                                        required
                                        onChange={newsLetterForm.handleChange}
                                        onBlur={newsLetterForm.handleBlur}
                                        value={newsLetterForm.values.last_name}
                                    />
                                    {newsLetterForm.touched.last_name && newsLetterForm.errors.last_name ? <p className='field-err-msg'>{newsLetterForm.errors.last_name}</p> : null}

                                </div>
                                <div className="col-xl-4 form-group">
                                    <input
                                        type="email"
                                        className="form-control"
                                        name="email"
                                        id="email"
                                        placeholder="Email*"
                                        required
                                        onChange={newsLetterForm.handleChange}
                                        onBlur={newsLetterForm.handleBlur}
                                        value={newsLetterForm.values.email}
                                    />
                                    {newsLetterForm.touched.email && newsLetterForm.errors.email ? <p className='field-err-msg'>{newsLetterForm.errors.email}</p> : null}
                                </div>

                            </div>

                            <div className="text-center"><button type="submit" onClick={newsLetterForm.handleSubmit} className='btn-send-msg'>Sign Up</button></div>
                        </form>
                        /*End Newsletter Form */
                        : ''
                }

            </div>
        </section>
    )
}

export default Newsletter