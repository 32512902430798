import { handleResponse } from "../_utils/handle-response";

const API_URL = process.env.REACT_APP_API_URL

export const newsletterApi = {
    subscribe
};

function subscribe(data) {
    const requestOptions = { method: 'POST',headers: {'Content-Type': 'application/json'}, body: JSON.stringify(data)};
    return fetch(`${API_URL}/newsletter`, requestOptions).then(handleResponse).then(data => {return data});
}