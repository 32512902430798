import { Outlet } from "react-router-dom"
import Banner from "./Components/Banner"
import Footer from "./Components/Footer"
import Header from "./Components/Header"
import Newsletter from "./Components/Newsletter"

// React SEO
import { HelmetProvider } from "react-helmet-async"
import SEO from "./Components/SEO"

function Layout() {
  return (
    <div id="App">
      <HelmetProvider>
        <SEO
          title="Mimik Sushi - Japanese Cuisine With a Modern Take"
          description="Home page of Mimik Sushi"
          name="Mimik Sushi"
          type="Home Page"
        />
        <Header />
        <Banner />
        <main id="main">
          <Outlet />
        </main>
        <Newsletter />
        <Footer />
      </HelmetProvider>
    </div>

  )
}

export default Layout