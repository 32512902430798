function MenuContent({value}) {

    const renderedSection = value.map((section) => {
        const menuItems = section.menuItems.map((menuItem) => {
            return (

                <div className="col-lg-4 menu-item" key={menuItem.name}>
                    <h4>{menuItem.name} <em className="quantity">{menuItem.description}</em></h4>
                    <p className="ingredients">
                        {menuItem.ingredients}
                    </p>
                    <p className="price">
                        {menuItem.price}
                    </p>
                </div>

            )
        })

        return (
            <div className="tab-pane fade active show pt-5" id={section.label} key={section.label}>
                <div className="tab-header text-center">
                    <h3>{section.label}</h3>
                    <h6><em>{section.description}</em></h6>
                </div>
                <div className="row gy-5">
                    {menuItems}
                </div>
            </div>
        )


    })

    return (
        <>
            {renderedSection}
        </>
    )
}

export default MenuContent