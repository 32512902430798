//imports
import { NavLink } from "react-router-dom"
import SEO from "../Components/SEO"

function Locations() {
  return (
    <section id="locations" className="locations">
      <SEO
        title="Locations — Mimik Sushi | Acton"
        description="Locations of Mimik Sushi"
        name="Mimik Sushi"
        type="Location page"
      />
      <div className="container" data-aos="fade-up">
        <div className="section-header">
          <p><span>OUR LOCATIONS</span></p>
        </div>
        <div className="row gy-4 justify-content-center">
          <div className="col-sm-6 mb-3 mb-sm-0">
            <div className="card h-100 rounded-0">
              <div className="card-body text-center">
                <h5 className="card-title">Mimik Acton</h5>
                <p className="card-text address">269 High St, London  <br /> W3 9BT</p>
                <NavLink className="btn-locations" to="/booking" >BOOK NOW</NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Locations