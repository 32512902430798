//React 
import { Route, Routes } from 'react-router-dom';
import { useEffect } from 'react';

//Styling
import "bootstrap/dist/css/bootstrap.min.css";
import Aos from 'aos';
import 'aos/dist/aos.css'

//Pages
import Home from './Pages/Home';
import Menu from './Pages/Menu';
import Contacts from './Pages/Contacts';
import Locations from './Pages/Locations';
import Layout from './Layout';
import Order from './Pages/Order';
import Booking from './Pages/Booking';
import ErrorPage from './Pages/ErrorPage';

function App() {
  useEffect(() => {
    var preloader = document.querySelector('#preloader');
    if (preloader) {
      // window.addEventListener('load', () => {
      preloader.remove();
      // });
    }
  }, [])

  useEffect(() => {
    Aos.init({
      duration: 1000,
      easing: 'ease-in-out',
      once: true,
      mirror: false
    });
  }, [])

  return (
    <Routes>
      <Route path='/' element={<Layout />}>
        <Route index element={<Home />} />
        <Route path='/menu' element={<Menu />} />
        <Route path='/order' element={<Order />} />
        <Route path='/contacts' element={<Contacts />} />
        <Route path='/locations' element={<Locations />} />
        <Route path='/booking' element={<Booking />} />
      </Route>
      <Route path='*' element={<ErrorPage />} />
    </Routes>
  );
}

export default App;