import { NavLink } from "react-router-dom"
import { Container, Navbar } from "react-bootstrap"
import { List, XCircle } from "react-bootstrap-icons"
import { useEffect } from "react"

function Header() {
    useEffect(() => {
        // Event Functions
        const activeMobileNav = (event) => {
            event.preventDefault();
            mobileNavToggle();
        }

        const scrollToMain = (event) => {
            const element = document.getElementById("main");
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
            if (document.querySelector('.mobile-nav-active')) {
                mobileNavToggle();
            }
        }

        const changeNavBarColorOnScroll = (event) => {
            event.preventDefault();

            if (window.scrollY > 50) {
                header.style.backgroundColor = "rgba(34, 34, 34, 0.8)";
                header.style.transition = "1s";
            }
            if (window.scrollY < 50) {
                header.style.removeProperty("background-color");
            }
        }

        // Helper Functions
        const mobileNavToggle = () => {
            document.querySelector('body').classList.toggle('mobile-nav-active');
            mobileNavShow.classList.toggle('d-none');
            mobileNavHide.classList.toggle('d-none');
        }

        // Helper variables
        const header = document.querySelector(".header")

        // Mobile Nav Toggle
        var mobileNavShow = document.querySelector('.mobile-nav-show');
        var mobileNavHide = document.querySelector('.mobile-nav-hide');

        // Event listeners
        document.querySelectorAll('.mobile-nav-toggle').forEach(el => {
            el.addEventListener('click', activeMobileNav)
        });

        document.querySelectorAll('.nav').forEach(navEl => {
            if (navEl.classList.contains('navbar')) {
                document.querySelectorAll('#navbar a').forEach(navbarLink => {
                    navbarLink.addEventListener('click', scrollToMain)
                });
            } else {
                navEl.addEventListener('click', scrollToMain)
            }
        });

        window.addEventListener('scroll', changeNavBarColorOnScroll)

        return () => {
            // Clean up event listeners
            window.removeEventListener('click', scrollToMain);
            window.removeEventListener('click', activeMobileNav);
            window.removeEventListener('scroll', changeNavBarColorOnScroll)
        }

    }, [])
    return (
        <header id="header" className="header fixed-top d-flex align-items-center " data-aos="fade-down">
            <Container className="container d-flex align-items-center justify-content-between">
                <NavLink className="logo d-flex align-items-center me-auto me-lg-0 nav" to="/"><img src="assets/img/mimik-logo.png" alt="" /></NavLink>

                <Navbar id="navbar" className="navbar nav">
                    <ul>
                        <li><NavLink to='/menu'>MENU</NavLink></li>
                        <li><NavLink to='/order' >ORDER</NavLink></li>
                        <li><NavLink to='/contacts' >CONTACT</NavLink></li>
                        <li><NavLink to='/locations' >LOCATIONS</NavLink></li>
                    </ul>
                </Navbar>

                <NavLink className="btn-book-a-table nav" to="/booking">Book Now</NavLink>
                <i className="mobile-nav-toggle mobile-nav-show"><List /></i>
                <i className="mobile-nav-toggle mobile-nav-hide d-none"><XCircle /></i>

            </Container>
        </header>
    )
}

export default Header   