import React, { useState } from 'react'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Swal from 'sweetalert2'

import { contactApi } from '../api/contact'
import SEO from '../Components/SEO';

function Contacts() {

    const [errMsg, setErrMsg] = useState("");
    const [successSendMsg, isSuccessSendMsg] = useState(false);
    const [submitting, isSubmitting] = useState(false)
    const contactForm = useFormik({
        initialValues: {
            name: '',
            email: '',
            subject: '',
            message: '',
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            name: Yup.string().required('Name is required'),
            email: Yup.string().required('Email is required'),
            subject: Yup.string().required('Subject is required'),
            message: Yup.string().required('Message is required'),
        }),
        onSubmit: (values) => {
            isSubmitting(true)
            contactApi.addContact(values).then((data) => {
                if (data.success) {
                    Swal.fire({
                        text: data.msg,
                        icon: 'success',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#ff6766',
                    })
                    isSuccessSendMsg(true)
                    isSubmitting(false)
                } else {
                    setErrMsg(data.msg);
                    isSubmitting(false)
                    return;
                }
            });
        }
    });

    return (
        <section id="contact" className="contact">
            <SEO
                title="Contact Us | Mimik Sushi - Japanese Cuisine With a Modern Take"
                description="Contact page of Mimik Sushi"
                name="Mimik Sushi"
                type="Contact page"
            />
            <div className="container" data-aos="fade-up">

                <div className="section-header">
                    <p><span>CONTACT US</span></p>
                    {
                        successSendMsg ?
                            <div className='text-center'>
                                <p className="fs-1 text-success">Your message has been sent.Thank you!</p>
                            </div>
                            : ''
                    }
                </div>

                {
                    !successSendMsg ?
                        <>
                            <div className="section-text">
                                <p>If you have any enquiries, please use this form to contact us</p>
                            </div>
                            <form className="php-email-form p-3 p-md-4">
                                <div className="row">
                                    <div className='col-xl-12'>
                                        {errMsg ?
                                            <div className='book-a-table error-message'>{errMsg}</div>
                                            : ''}
                                    </div>
                                    <div className="col-xl-6 form-group">
                                        <input
                                            type="text"
                                            name="name"
                                            className="form-control"
                                            id="name"
                                            placeholder="Name*"
                                            required
                                            onChange={contactForm.handleChange}
                                            onBlur={contactForm.handleBlur}
                                            value={contactForm.values.name}
                                        />
                                        {contactForm.touched.name && contactForm.errors.name ? <p className='field-err-msg'>{contactForm.errors.name}</p> : null}

                                    </div>
                                    <div className="col-xl-6 form-group">
                                        <input
                                            type="email"
                                            className="form-control"
                                            name="email"
                                            id="email"
                                            placeholder="Email*"
                                            required
                                            onChange={contactForm.handleChange}
                                            onBlur={contactForm.handleBlur}
                                            value={contactForm.values.email}
                                        />
                                        {contactForm.touched.email && contactForm.errors.email ? <p className='field-err-msg'>{contactForm.errors.email}</p> : null}

                                    </div>
                                </div>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="subject"
                                        id="subject"
                                        placeholder="Subject*"
                                        required
                                        onChange={contactForm.handleChange}
                                        onBlur={contactForm.handleBlur}
                                        value={contactForm.values.subject}
                                    />
                                    {contactForm.touched.subject && contactForm.errors.subject ? <p className='field-err-msg'>{contactForm.errors.subject}</p> : null}

                                </div>
                                <div className="form-group form-message" id="form-message">
                                    <textarea
                                        className="form-control"
                                        name="message" rows="5"
                                        placeholder="Message*"
                                        required
                                        onChange={contactForm.handleChange}
                                        onBlur={contactForm.handleBlur}
                                        value={contactForm.values.message}
                                    ></textarea>
                                    {contactForm.touched.message && contactForm.errors.message ? <p className='field-err-msg'>{contactForm.errors.message}</p> : null}
                                </div>
                                <div className="my-3">
                                </div>

                                <div className="text-center">
                                    <button type="submit" className='btn-send-msg' onClick={contactForm.handleSubmit}>
                                        {
                                            !submitting ?
                                                'Send Message'
                                                : <img src="assets/img/icon/spinner-solid.svg" />
                                        }
                                    </button>
                                </div>

                            </form>
                        </>
                        : ''
                    /*End Contact Form */
                }

            </div>
        </section>
    )
}

export default Contacts